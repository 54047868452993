.product-filter-label {
	display: flex;
	align-items: center;
	position: relative;
	padding-left: 30px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 16px;
	line-height: 20px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-weight: normal;

	/* Hide the browser's default radio button */
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}

	/* Create a custom radio button */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: #fff;
		border-radius: 50%;
		border: 1px solid #3f3a35;

		/* Create the indicator (the dot/circle - hidden when not checked) */
		&:after {
			content: "";
			position: absolute;
			display: none;
			top: 6px;
			left: 6px;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background: white;
		}
	}

	/* On mouse-over, add a grey background color */
	&:hover input ~ .checkmark {
		background-color: #eee;
	}

	/* When the radio button is checked, add a red background */
	input:checked ~ .checkmark {
		background-color: $brand-primary;
		border: 1px solid $brand-primary;

		/* Show the indicator (dot/circle) when checked */
		&:after {
			display: block;
		}
	}
}


@media screen and (min-width: $screen-lg-min) {
	.product-filter-label {
		&.twocol {
			float: left;
			max-width: 50%;
			width: 100%;
		}
	}

	.filter-desktop {
		.prislusenstvo-item {
			width: 25%;
		}
	}
}
