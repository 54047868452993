@mixin brands-in-a-row {
	flex-direction: row;
	justify-content: space-evenly;
	margin-bottom: 2.6rem;
}

#filter-mobile {
	.collapsing {
		-webkit-transition: none;
		transition: none;
		display: none;
	}

	hr {
		border-top: $filter-rule;
	}

	nav {
		height: 50px;
		display: flex;
		align-items: center;

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			color: $text-color;
			font-weight: bold;
			width: 50%;
			text-align: center;
			padding: 1.2rem 0;

			&.active {
				color: $brand-primary;
				text-decoration: none;
			}

			&::before {
				width: 25px;
				height: 25px;
				display: inline-block;
				margin-right: 10px;
				content: "";
			}
		}

		.filter-nav-item {
			border-right: $filter-rule;

			&::before {
				background: url("../../../static/img/icon-filter-grey.svg") no-repeat;
			}

			&.active {
				&::before {
					background: url("../../../static/img/icon-filter-red.svg") no-repeat;
				}
			}
		}

		.order-nav-item {
			&::before {
				background: url("../../../static/img/icon-sort-grey.svg") no-repeat;
			}

			&.active {
				&::before {
					background: url("../../../static/img/icon-sort-red.svg") no-repeat;
				}
			}
		}
	}

	p {
		text-align: center;
		font-weight: bold;
		font-size: 16px;
	}

	.input-wrapper {
		margin-left: 10vw;

		@supports (width: max-content) {
			width: max-content;
			margin: 0 auto;
		}
	}

	.brands-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			max-width: 120px;
			margin-bottom: 1.2rem;
		}

		&.count-2 {
			@media screen and (min-width: 380px) {
				@include brands-in-a-row;
			}
		}

		&.count-3 {
			@media screen and (min-width: 500px) {
				@include brands-in-a-row;
			}
		}
	}

	.prislusenstvo-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 3rem;

		.name {
			text-transform: uppercase;
			color: $text-color;
			font-size: 1.6rem;
		}

		&.active {
			.name {
				color: #da1a32;
			}
		}
	}

	.btn {
		padding: 0.9rem 0;
		font-weight: bold;
	}

	.btn-primary {
		font-size: 1.6rem;
	}
}
