.desktop-product-filter {
	$desktop-product-filter: &;
	display: none;
	margin-top: 4rem;
	margin-bottom: 4rem;

	&__parameters {
		display: flex;

		& > * {
			padding-left: 3.6rem;
			padding-right: 3.6rem;
			flex-grow: 0;
			flex-shrink: 0;
			flex-basis: 50%;
		}

		& :first-child {
			padding-left: 0;
		}

		& :last-child {
			padding-right: 0;
		}

		& > * + * {
			border-left: 1px solid $light-grey;
		}

		&--with-varieties {
			& > * {
				flex-basis: 33.333333%;
			}
		}
	}


	&__section_heading {
		font-weight: bold;
		font-size: 1.6rem;
	}

	&__brands-container {
		display: flex;
		flex-direction: column;
	}

	&__brands {
		display: flex;
		flex-grow: 1;
		align-items: center;

		& > * {
			margin-left: 0;
			margin-right: 0;
		}

		& > * + * {
			margin-left: 3rem;
		}
	}

	&__brand-link {
		@media (hover: hover) {
			&:hover {
				text-decoration: none;
			}
		}
	}

	&__categories {
		& > * {
			margin-bottom: 1rem;
		}
	}

	&__category-link {
		color: $text-color;
		font-size: 1.6rem;
		text-transform: uppercase;
		display: flex;
		align-items: center;

		&__checkbox {
			height: 20px;
			width: 20px;
			background-color: #fff;
			border-radius: 50%;
			border: 1px solid $text-color;
			margin-right: 1rem;
			flex-shrink: 0;

			&--active {
				background-color: $brand-primary;
				border: 1px solid $brand-primary;

				&:before {
					content: "";
					display: block;
					width: 6px;
					height: 6px;
					border-radius: 50%;
					background: white;
					transform: translate(100%, 100%);
				}
			}
		}


		@media (hover: hover) {
			$category-link: &;

			&:hover {
				text-decoration: none;
				color: $text-color;

				#{$category-link}__checkbox {
					background-color: #eee;

					&--active {
						border: 1px solid $text-color;
						background-color: #fff;
					}
				}
			}
		}
	}

	.bottom {
		margin-top: 4rem;
		display: flex;
		align-items: center;

		.btn-link {
			padding-right: 2.2rem;
		}

		hr {
			width: 100%;
			border-top: 1px solid $light-grey;
		}

		form {
			flex: 1 0 auto;
			margin-left: 2.2rem;

			.form-control {
				background-color: #eBe8e6;
			}

			label {
				margin-right: 2rem;
			}
		}
	}

	&__order-btn {
		text-transform: none;
		width: 19.4rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__order-label {
		margin-left: 2.2rem;
		margin-right: 2rem;
		font-weight: 700;
		flex-shrink: 0;
	}

	.btn-link {
		font-weight: bold;

		&:hover {
			text-decoration: none;
		}
	}

	.prislusenstvo-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 2rem 0;
		margin-bottom: 1.5rem;
		width: 33.3333333333%;
		float: left;

		> span:first-of-type {
			text-transform: uppercase;
			color: #3f3a35;
			font-size: 1.6rem;
			margin-bottom: 0.8rem;
			margin-top: 1rem;
		}

		&:hover {
			text-decoration: none;
		}

		&.active {
			> span:first-of-type {
				color: #da1a32;
			}
		}
	}

	@media screen and (min-width: $screen-md-min) {
		display: block;
	}

	@media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
		&--with-varieties {
			#{$desktop-product-filter}__parameters {
				& > * {
					flex-basis: 33.33333333333%;
				}
			}

			#{$desktop-product-filter}__types-container {
				flex-basis: 36%;
			}

			#{$desktop-product-filter}__brands {
				flex-direction: column;

				& > * {
					margin-left: 0;
				}

				& > * + * {
					margin-top: 3rem;
				}
			}
		}
	}

	@media screen and (min-width: $screen-lg-min) {
		&--with-varieties {
			#{$desktop-product-filter}__brands-container {
				flex-basis: 50%;
			}

			#{$desktop-product-filter}__types-container {
				flex-basis: 30%;
			}

			#{$desktop-product-filter}__varieties-container {
				flex-basis: 20%;
			}
		}

		&--without-varieties {
			#{$desktop-product-filter}__types {
				display: flex;
				flex-wrap: wrap;

				& > * {
					flex: 0 0 50%;
					max-width: 50%
				}
			}
		}
	}
}
